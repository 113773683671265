<template>
	<v-data-table :headers="headers" :items="results">
		<!--		<template v-slot:top>-->
		<!--			<v-row justify="end">-->
		<!--				<v-col cols="auto">-->
		<!--					<v-btn color="success" class="mr-2" @click="export_csv">-->
		<!--						<v-icon>{{ $icons.csv }}</v-icon>-->
		<!--					</v-btn>-->
		<!--				</v-col>-->
		<!--			</v-row>-->
		<!--		</template>-->
	</v-data-table>
</template>

<script>
export default {
	name: "BenchmarkResults",
	props: {
		results: Array
	},
	data: () => ({
		headers: [
			{
				text: 'Topic',
				align: 'start',
				sortable: true,
				value: 'topic',
			},
			{
				text: 'Actual',
				align: 'start',
				sortable: true,
				value: 'actual',
			},
		]
	}),
	methods: {
		// export_csv(){
		// 	let csvContent = "data:text/csv;charset=utf-8,"
		// 		+ this.results[0].map(h => h)
		// 		+ this.results.map(e => {
		// 			console.log(e);
		// 			// e.join(",")
		// 		})
		// 			// .join("\n");
		//
		// 	let encodedUri = encodeURI(csvContent);
		// 	let link = document.createElement("a");
		// 	link.setAttribute("href", encodedUri);
		// 	link.setAttribute("download", `my_data.csv`);
		// 	document.body.appendChild(link); // Required for FF
		//
		// 	link.click();
		// }
	}
}
</script>